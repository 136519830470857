<template>
  <v-container class="body-card">
    <payment-confirmation
      :dialog="payment_confirmation"
      :onHandlerPay="doPayment"
      :detailTransaction="modalDetailTransaction"
      :onHandlerCancel="cancelPayButton"
      />

    <v-row class="d-flex justify-space-between">
      <v-col cols="auto" class="text-h6">
        {{$t("SubtotalForItem")}}
      </v-col>
      <v-col cols="auto" class="text-right text-h6">
        {{ Intl.NumberFormat().format(total_amount) }}
      </v-col>
    </v-row>
    <p class="mb-0 py-4 font-weight-medium">{{$t("ChoosePaymentMethod")}}</p>
    <v-radio-group v-model="payment_channel_selected" class="pt-0 ma-0">
      <v-row
        class="white-bg border-bottom"
        v-for="(payment_channel, index) in payment_channels"
        :key="index"
      >
        <v-col cols="12" class="pt-0 pb-0">
          <v-list-item class="pa-0">
            <v-list-item-avatar color="white">
              <img :src="payment_channel.icon" alt="item.title" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="black--text">
                {{payment_channel.payment_channel_name}}
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-radio class="pt-0" :key="index" :value="payment_channel"></v-radio>
            </v-list-item-action>
          </v-list-item>
        </v-col>
      </v-row>
    </v-radio-group>
    <v-footer fixed app width="auto" color="white" elevation="3">
      <v-container class="ma-0 pa-0">
        <v-row class="ma-2 pa-0">
          <v-col class="ma-0 pa-0 d-flex justify-start" align-self="center">
            <h2 class="labelgrandtotal">{{ $t("Total") }}</h2>
          </v-col>
          <v-col class="ma-0 pa-0 d-flex justify-end" align-self="center">
            <h2 class="grandtotal" width="auto">
              {{ Intl.NumberFormat().format(this.total_amount_to_pay) }}
            </h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="ma-0 pa-0 pl-2 pr-2 pb-4"
            lg="2"
            md="3"
            cols="12"
            align-self="center"
          >
            <v-btn
              :disabled="payment_channel_selected==null"
              rounded
              block
              class="text-capitalize primary"
              @click="openPaymentConfirmation()"
            >
              {{ $t("Pay") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-container>
</template>
<script>
import PaymentConfirmation from "@/components/PaymentConfirmation.vue";

export default {
  name: "PaymentIndex",
  components: {PaymentConfirmation, },
  data() {
    return {
      payment_channel_selected:null,
      payment_channels: [],
      payment_confirmation:false,
      detailTransaction:{},
      modalDetailTransaction:{},
      total_amount:"",
      total_amount_to_pay:"",
    }
  },
  watch:{
    payment_channel_selected(){
      var channel;
      var mdr_value = 0;
      var fee_value = 0;
      var disburment_value = 0;
      channel = this.payment_channel_selected
      let price_to_pay = 0
      if(channel.payment_channels_config != null){
        var payment_channel_config = channel.payment_channels_config[0]
        
        if(payment_channel_config.mdr_type != "")  {
          if(payment_channel_config.mdr_type == "flat"){
            mdr_value = payment_channel_config.mdr
          } else {
            mdr_value = (payment_channel_config.mdr /100 ) * this.detailTransaction.payment_detail.amount
          }
        }
        
        if(payment_channel_config.is_fee_included == false){
          if(payment_channel_config.fee_type == "flat"){
            fee_value = payment_channel_config.fee
          } else {
            fee_value = (payment_channel_config.fee /100 ) * this.detailTransaction.payment_detail.amount
          }
        }
      }
      
      price_to_pay = this.detailTransaction.payment_detail.amount - mdr_value + fee_value
      
      if(channel.disbursement_config != null){
        for(let i = 0; i < channel.disbursement_config.length; i++){
          if(channel.disbursement_config[i].is_fee_included == false){
            if(channel.disbursement_config[i].disbursement_rate_type == 'flat'){
              disburment_value=channel.disbursement_config[i].disbursement_rate
            } else {
              disburment_value=(channel.disbursement_config[i].disbursement_rate/100) * price_to_pay
            }
          }
          price_to_pay += disburment_value
  
        }
      }
      price_to_pay = Math.round(price_to_pay)
      this.total_amount_to_pay = price_to_pay
    }
  },
  methods: {
    openPaymentConfirmation() {
      let items = this.detailTransaction.items
      let newItems = items.filter(element => element.item_name != "Transaction Fee")
      
      var channel;
      var mdr_value = 0;
      var fee_value = 0;
      var disburment_value = 0;
      channel = this.payment_channel_selected
      let price_to_pay = 0
      var payment_channel_config = channel.payment_channels_config[0]
      
      if(payment_channel_config.mdr_type != "")  {
        if(payment_channel_config.mdr_type == "flat"){
          mdr_value = payment_channel_config.mdr
        } else {
          mdr_value = (payment_channel_config.mdr /100 ) * this.detailTransaction.payment_detail.amount
        }
      }
      
      if(payment_channel_config.is_fee_included == false){
        if(payment_channel_config.fee_type == "flat"){
          fee_value = payment_channel_config.fee
        } else {
          fee_value = (payment_channel_config.fee /100 ) * this.detailTransaction.payment_detail.amount
        }
      }
      
      this.modalDetailTransaction.payment_channel_config = (mdr_value - fee_value) < 0 ? (mdr_value - fee_value)*(-1) : (mdr_value - fee_value)
      this.modalDetailTransaction.payment_channel_config=Math.round(this.modalDetailTransaction.payment_channel_config)
      price_to_pay = this.detailTransaction.payment_detail.amount - mdr_value + fee_value

      var disburment_amount = 0
      if(channel.disbursement_config != null){
        for(let i = 0; i < channel.disbursement_config.length; i++){
          if(channel.disbursement_config[i].is_fee_included == false){
            if(channel.disbursement_config[i].disbursement_rate_type == 'flat'){
              disburment_value=channel.disbursement_config[i].disbursement_rate
            } else {
              disburment_value=(channel.disbursement_config[i].disbursement_rate/100) * price_to_pay
            }
          }
          price_to_pay += disburment_value
          disburment_amount += disburment_value
  
        }
      }
      this.modalDetailTransaction.disbursement_config = Math.round(disburment_amount)
      price_to_pay = Math.round(price_to_pay)
      this.modalDetailTransaction.items= newItems
      this.modalDetailTransaction.channelCode = channel.payment_channel_code
      this.modalDetailTransaction.trxAmount = price_to_pay

      this.togglePaymentConfirmation(true)
    },
    doPayment(){
      let updateInfo = {
        transaction_splitting_formula_id : this.modalDetailTransaction.transaction_splitting_formula_id,
        gross_amount : this.modalDetailTransaction.trxFee + this.modalDetailTransaction.trxAmount,
        channel_code : this.modalDetailTransaction.channelCode,
        payment_channels_be_id : ""+this.payment_channel_selected.payment_channels_be_id+""

      }
      localStorage.setItem('trxDetail', JSON.stringify(updateInfo))
      this.togglePaymentConfirmation(false)
      this.$router.push({ name: "DoPayment", query: { tokentrx: this.$route.params.payment_token } });
    },
    cancelPayButton(){
      // this.payment_confirmation=false
      localStorage.removeItem("trxDetail");
      this.togglePaymentConfirmation(false)
    },
    togglePaymentConfirmation(boolean){
      this.payment_confirmation=boolean
    }
  },
  mounted(){
    localStorage.setItem("token", this.$route.params.payment_token);
    this.$store.dispatch("payment/paymentDetail", this.$route.params.payment_token)
    .then((response) => {
      if(response.status_code == 200){
         this.detailTransaction=response.data.payment_info
         this.total_amount=this.detailTransaction.payment_detail.amount
         this.total_amount_to_pay=this.detailTransaction.payment_detail.amount
      }
    })

    this.$store.dispatch("payment/listPartnerPaymentChannels")
    .then((response) => {
      if(response.status_code == 200){
        this.payment_channels = []
        for (let payment_channel of response.data){
          if(payment_channel.payment_channel_code == 'alepay-credit_debit'){
            payment_channel.icon=require("../../assets/logo-alepay.jpg")
          } else if(payment_channel.payment_channel_code == 'moca-credit_debit'){
            payment_channel.icon=require("../../assets/logo-moca.jpg")
          } else if(payment_channel.payment_channel_code == 'momo-credit_debit'){
            payment_channel.icon=require("../../assets/momo.png")
          } else if(payment_channel.payment_channel_code == 'ALEPAY-serverbase'){
            payment_channel.icon=require("../../assets/logo-alepay.jpg")
          }
          this.payment_channels.push(payment_channel)
        } 
      } 
    })
  }
};
</script>

<style lang="scss" scoped>
.body-card {
  background-color: #f5fff7;
  height: 100%;
  width: 100%;
  padding-top: 0;
}
#spacer {
  background: #f5fff7;
  padding: 6px;
}
.font-weight-500 {
  font-weight: 500;
}
.v-text-field--outlined fieldset {
  border-color: #5db968 !important;
}

.v-text-field--outlined fieldset {
  border-color: #5db968 !important;
}
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 70px;
  text-align: center;
  mix-blend-mode: normal;
}
.error-password {
  color: #ff5252;
}
.white-bg {
  background: white
}
.border-bottom{
  border-bottom: 1px solid #d3caca
}
/* If the screen size is 601px or more, set the font-size of <div> to 80px */
@media only screen and (min-width: 601px) {
  .item-add {
    background-color: #ffffff;
    width: 100%;
  }

  .btn-click-change-password {
    text-transform: capitalize;
    left: 0;
  }
}

/* If the screen max size is 600px, set the font-size of <div> to 80px */
@media only screen and (max-width: 600px) {
  .item-add {
    width: 100%;
    background-color: #ffffff;
  }

  .btn-click-change-password {
    text-transform: capitalize;
    left: 0;
  }
}
</style>